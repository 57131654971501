.pricing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
}

@media only screen and (max-width: 768px) {
  .pricing {
    grid-template-columns: 1fr;
  }
}

.column {
  display: grid;
  align-content: space-between;
  padding: 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: var(--cc-colour-deepsea);
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid var(--cc-colour-deepsea);
  border-radius: 4px;

  transform: scale(0.95);
  transition: all 0.05s ease;
}

.column:hover {
  text-decoration: none;
  transform: scale(1);
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
}

.column:visited {
  color: var(--cc-colour-deepsea);
}

.column ul {
  list-style: none;
  padding: 0;
  font-size: 1.5rem;
}

.pricing h3 {
  margin: 0;
  font-size: 1.2rem;
}

.price {
  font-size: 2.7rem;
  font-weight: bold;
}

.subprice {
  font-size: 1rem;
  font-weight: bold;
  margin-left: 4px;
}

.button {
  padding: 12px 32px;
  text-align: center;
}

.point {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
}
