.logo {
  position: relative;
  width: 128px;
  height: 64px;
}

.logo .img {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.1s ease;
}

.logo:hover .img {
  width: 100%;
}
