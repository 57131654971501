.form {
  display: grid;
  grid-template-columns: minmax(350px, 1fr);
  justify-content: center;
  row-gap: 18px;
  padding: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 2px solid var(--cc-colour-deepsea);
  border-radius: 4px;
}

.body h2 {
  margin: 0;
}

.errorMessage {
  color: var(--cc-colour-error);
}

.fields {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 18px;
}

.button {
  justify-self: end;
}

.disabled {
  opacity: 0.5;
}

.checkbox {
  justify-self: start;
}

.footer {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  row-gap: 18px;
}

.switchAction {
  margin-top: 12px;
  margin-bottom: 18px;
}

.switchAction,
.switchAction button {
  font-size: 0.9rem;
  font-style: italic;
}

.firstLast {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}

.firstLast label {
  grid-template-columns: 1fr;
  row-gap: 6px;
}

.resetPassword {
  font-size: 0.9rem;
}

/* Mobile */
@media only screen and (max-width: 482px) {
  .form {
    grid-template-columns: 1fr;
  }

  .firstLast {
    grid-template-columns: 1fr;
  }

  .footer {
    grid-template-columns: 1fr;
  }

  .footer .button {
    justify-self: stretch;
  }
}
