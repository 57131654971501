.footer {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  padding: 32px;

  font-size: 0.9rem;
}

.footer a {
  color: var(--cc-colour-deepsea);
}

.cc {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}

.cc .img {
  width: 24px;
}
