/* Regular button */
.button {
  font-family: var(--font-family-button);
  font-size: var(--font-size-button);
  font-weight: var(--font-weight-button);
  border: 2px solid var(--cc-colour-deepsea);
  color: var(--cc-colour-deepsea);
  background-color: var(--cc-colour-seaspray-light);
  border-radius: 4px;
  padding: 12px 24px;
  cursor: pointer;
}

.button:hover {
  text-decoration: none;
  background-color: var(--cc-colour-seaspray-hover);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Button that looks like a link */
.linkButton {
  display: inline;
  padding: 0;
  border: none;
  color: var(--cc-colour-link);
  background-color: transparent;
  font-family: var(--font-family-body);
  font-size: var(--font-size-body);
  cursor: pointer;
}

.linkButton:hover {
  text-decoration: dotted underline;
}

/* Link that looks like a button */
.buttonLink {
  composes: button;
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  color: var(--cc-colour-deepsea);
  font-family: var(--font-family-button);
  font-size: var(--font-size-button);
  font-weight: var(--font-weight-button);
}

.buttonLink:visited {
  color: var(--cc-colour-deepsea);
}
