.faq {
  list-style: none;
  padding: 0;
}

.faq li span {
  font-family: var(--font-family-subheading);
  font-size: var(--font-size-faq-question);
  font-weight: var(--font-weight-subheading);
}
