.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 1.25rem;
}

.modal.opened {
  display: block;
}

.content {
  width: 80%;
  margin: 15% auto;
  padding: 32px;
  border-radius: 4px;
  background-color: var(--cc-colour-sand);
}

.heading {
  margin-top: 0;
}
