.description {
  font-family: var(--font-family-subheading);
  font-size: var(--font-size-subheading);
  font-weight: var(--font-weight-subheading);
  margin-top: 0;
}

.loading {
  visibility: hidden;
}

@media only screen and (max-width: 700px) {
  .description {
    max-width: 380px;
  }
}

@media only screen and (max-width: 500px) {
  .heading {
    font-size: 2em;
  }

  .description {
    font-size: 18px;
    max-width: 300px;
  }
}

@media only screen and (max-width: 300px) {
  .description {
    font-size: 16px;
  }
}
