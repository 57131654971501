.dropdown {
  position: relative;
  text-align: right;
}

.button {
  width: 100%;
}

.items {
  display: grid;
  grid-auto-flow: row;
  position: absolute;
  min-width: 100%;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.85);
}

.items:not(.expanded) {
  visibility: hidden;
}

.item {
  margin: 0;
}
