.input {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 100px minmax(0, 1fr);
  column-gap: 8px;
  justify-items: start;
  align-items: center;
}

/* Mobile */
@media only screen and (max-width: 482px) {
  .input {
    grid-template-columns: 1fr;
    row-gap: 8px;
  }
}

.input input {
  width: 100%;
  padding: 12px;
  background-color: var(--cc-colour-seaspray-light);
  border: 2px solid var(--cc-colour-deepsea);
  border-radius: 4px;
  font-size: 18px;
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;
  user-select: none;
}

.checkbox:not(.disabled) {
  cursor: pointer;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox .checkmarkBg {
  position: relative;
  height: 25px;
  width: 25px;
  border: 2px solid var(--cc-colour-deepsea);
  border-radius: 4px;
  background-color: var(--cc-colour-seaspray-light);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.checkmark:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 3px;
  background-color: #fff;
  left: 6px;
  top: 15px;
}

.checkmark:after {
  content: "";
  position: absolute;
  width: 3px;
  height: 17px;
  background-color: #fff;
  left: 10px;
  top: 1px;
}

.checkbox input:checked ~ .checkmarkBg {
  background-color: var(--cc-colour-deepsea);
}

.checkbox input:not(:checked) ~ .checkmarkBg .checkmark {
  display: none;
}
